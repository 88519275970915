.right{
  justify-content: flex-end;
}
.logo{
  display: flex;
  flex-direction: row;
  background: transparent;
  .logo-container{
      display: flex;
      align-items: center;
      img{
        width: 63px;
        height: 57px;
        cursor: pointer;
        margin-right: 20px;
        @include mobile{
          width: 43px;
          height: 43px;
        }
      }
      .title{
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
      }
  }
}