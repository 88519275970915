.attract{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px;
  @include mobile{
    padding: 10px;
  }
  .attract-body{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @include tablet{
      position: relative;
      top: 200px;
    }
    @include mobile{
      flex-direction: column;
      margin-top: 30px;
    }
    .left-container{
      width: calc(50% - 20px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: 160px;
      @include tablet{
        margin-left: 0px;
      }
      @include mobile{
        width: 100%;
        margin-left: 0px;
      }
      .subtitle-attract-container{
        font-weight: 600;
        font-size: 20px;
        line-height: 31px;
        color: #FFA500;
        margin-bottom: 20px;
        @include mobile{
          font-size: 15px;
        }
      }
      .title-attract-container{
        font-style: normal;
        font-weight: 600;
        font-size: 96px;
        line-height: 120px;
        color: #000000;
        @include tablet{
          font-size: 90px;
        }
        @include mobile{
          line-height: 0px;
          font-size: 35px;
        }
        span{
          color: #220A6F;
        }
        margin-bottom: 45px;
      }
      .paragraph-attract-container{
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: left;
        color: #000000;
        max-width: 644px;
        margin-bottom: 44px;
        @include mobile{
          max-width: 330px;
        }
      }
      .button-container{
        display: flex;
        align-items: center;
        transition: ease-in 3s;
        .img-left{
          width: 200px;
          margin-right: 20px;
          max-height: 100px;
          @include mobile{
            width: 180px;
            margin-right: 20px;
            max-height: 100px;
            margin-bottom: 20px;
            @media (max-height: 799px) {
              width: 155px;
            }
          }
        }
        .button-right{
          display: flex;
          align-items: center;
          cursor: pointer;
          img{
            width: 54px;
            height: 54px;
            margin-right: 10px;
          }
          .title-play{
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #220A6F;
          }
        }
      }
      .form-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .title-input{
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 25px;
          color: #220A6F;
          margin-bottom: 10px;
        }
        .container-input {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;
          width: 600px;
          input {
            color: #220A6F;
            font-size: 16px;
            text-align: left;
            padding: 15px 20px;
            border-radius: 10px;
            border: #220A6F 1px solid;
            width: 100%;

            &:focus {
              border: #6D28D9 !important;
            }
          }

          .error-message {
            color: red;
            margin-top: 20px;
            padding: 10px;
            position: relative;
            left: -100%;
            transform: translateX(100%);
            text-align: left;
          }

          .button-send {
            margin-top: 20px;
            padding: 14px 16px;
            color: white;
            width: 121px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            box-shadow: none;
            background: linear-gradient(180deg, #4338CA 0%, #6D28D9 100%);
            border-radius: 10px;
            cursor: pointer;
            border: none;
          }
        }
      }
    }
    .right-container{
      width: calc(50% - 20px);
      position: relative;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      @include mobile{
        width: 100%;
      }
      .background{
        top: -881px;
        position: absolute;
        left: 270.21px;
        background: linear-gradient(180deg, #4338CA 0%, #6D28D9 100%);
        border-radius: 10px;
        transform: rotate(-40.25deg);
        width: 1042px;
        height: 1725px;
        @include mobile{
          display: none;
        }
      }
      img{
        position: relative;
        z-index: 111;
        width: 715px;
        height: 680px;
        @include tablet{
          width: 530px;
          height: 500px;
        }
        @include mobile{
          @media (min-height: 800px) {
            left: -21px;
            width: 395px;
            height: 294px;
          }
          @media (max-height: 799px) {
            display: none;
          }
        }
      }
    }
  }
  .footer{
    position: absolute;
    left: 50px;
    bottom: 50px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-footer{
      margin-right: 30px;
      font-size: 16px;
      font-weight: 500;
      color: #22286F;
      @include mobile{
        margin-right: 50px;
        font-size: 12px;
      }
    }
  }
  .scroll{
    margin-top: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    .title-scroll{
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }
    img{
      width: 15px;
      height: 25px;
    }
  }
}