.description-steps{
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 100px;
  img{
    margin-right: 20px;
  }
  .content{
    font-style: normal;
    font-weight: 600;
    line-height: 10px;
    text-align: left;
    color: #000000;
  }

}