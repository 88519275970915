.explain{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px;
  .explain-body{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img{
      width: 900px;
      height: 550px;
      margin-bottom:30px;
    }
    .title-explain{
      font-style: normal;
      font-weight: 600;
      font-size: 38px;
      line-height: 40px;
      color: #000000;
      text-align: center;
      margin-bottom: 40px;
    }
    .description{
      font-weight: 500;
      font-size: 19px;
      line-height: 25px;
      max-width: 95%;
      text-align: center;
      color: #000000;
    }
  }
  .scroll{
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    .title-scroll{
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
    }
    img{
      width: 15px;
      height: 25px;
    }
  }
}