.view--policy{
  display: flex;
  flex-direction: column;
  padding: 50px;
  .title-first{
    margin-top: 80px;
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: 1.75rem;
    font-weight: 600;
    color: black;
    margin-bottom: 80px;
  }
  .container-description{
    display: flex;
    flex-direction: column;
    padding: 20px;
    .title-part{
      font-size: 1.5rem;
      letter-spacing: 0;
      line-height: 1.75rem;
      font-weight: 600;
      margin-bottom: 30px;
    }
    .description-part{
      font-size: 1.125rem;
      letter-spacing: .025rem;
      line-height: 1.75rem;
      font-weight: 400;
      max-width: 100%;
      color: gray;
    }
  }
}