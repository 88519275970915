.contact{
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px 0 0 50px;
  .contact-body{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 130px;
    .left-part{
      width: calc(55% - 20px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: 60px;
      margin-right: 154px;
    }
    .title-left-part{
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 50px;
      color: #220A6F;
      margin-bottom: 45px;
    }
    .paragraph-left-part{
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      text-align: left;
      color: #220A6F;
      margin-bottom: 44px;
    }
    .title-input{
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 25px;
      color: #220A6F;
      margin-bottom: 10px;
    }
    .container-input{
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      width: 100%;
      input{
        color: #220A6F;
        font-size: 16px;
        text-align: left;
        padding: 15px 20px;
        border-radius: 10px;
        border: #220A6F 1px solid;
        width: 100%;
        &:focus{
          border: #6D28D9 !important;
        }
      }
      .error-message{
        color: red;
        margin-top: 20px;
        padding: 10px;
        position: relative;
        left: -100%;
        transform: translateX(100%);
        text-align: left;
      }
      .button-send{
        margin-top: 20px;
        padding: 14px 16px;
        color: white;
        width: 121px;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        box-shadow: none;
        background: linear-gradient(180deg, #4338CA 0%, #6D28D9 100%);
        border-radius: 10px;
        cursor: pointer;
        border: none;
      }
    }
    .right-part{
      margin-left: 150px;
      width: calc(45% - 20px);
      position: relative;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      .background{
        width: 100%;
        height: 101vh;
        top: -239px;
        position: absolute;
        background: linear-gradient(180deg, #4338CA 0%, #6D28D9 100%);
      }
      img{
        position: relative;
        z-index: 111;
        top: -53px;
        left: -183px;
      }
    }
  }
}