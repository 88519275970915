.feature{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 50px;
  .body-feature{
    display: flex;
    align-items: center;
    margin-top:110px ;
    justify-content: space-between;
    width: 100%;
    .left-part-feature{
      width: calc(50% - 20px);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: 150px;
      .subtitle-feature{
        font-weight: 600;
        font-size: 20px;
        line-height: 31px;
        color: #FFA500;
        margin-bottom: 30px;
      }
      .title-feature{
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 50px;
        color: #220A6F;
        margin-bottom: 45px;
      }
    }
    .right-part-feature{
      width: calc(50% - 20px);
      position: relative;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      .background{
        width: 841px;
        height: 833px;
        left: -43px;
        top: 485px;
        border-radius: 50%;
        position: absolute;
        background: linear-gradient(180deg, #4338CA 0%, #6D28D9 100%);
      }
      img{
        position: relative;
        z-index: 111;
        top: 20px;
        width: 500px;
        height: 680px;
      }
    }
  }
  .scroll{
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 111;
    justify-content: flex-end;
    .title-scroll{
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      span{
        color: white;
      }
    }
    img{
      width: 15px;
      height: 25px;
    }
  }
}