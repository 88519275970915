//Lib


// Utils
@import "./utils/reset";
@import "./utils/sizes";
@import "./utils/mixins";
@import "./utils/_settings";
@import "./utils/_color";
@import "./utils/mui";

// Layouts
@import "../layouts/main-header/main-header";


// Components
@import "../components/attract/attract.scss";
@import "../components/logo/logo.scss";
@import "../views/mobile-explain/mobile-explain";
@import "../components/feature/feature.scss";
@import "../components/dashboard-explain/dashboard-explain.scss";
@import "../components/contact/contact.scss";
@import "../components/description-steps/description-steps";
@import "../components/loader/loader.scss";

// Pages
@import "../views/home/home.scss";
@import "../views/policy/policy.scss";



#app {
  height: 100%;
}
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}









