.dashboard-explain{
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 50px;
  .body-dashboard-explain{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .left-part-dashboard-explain{
      width: calc(60% - 20px);
      position: relative;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      .background{
        width: 494px;
        height: 101vh;
        left: -51px;
        top: -248px;
        position: absolute;
        background: linear-gradient(180deg, #4338CA 0%, #6D28D9 100%);
      }
      img{
        position: relative;
        z-index: 111;
        top: 36px;
        left: -13px;
        width: 788px;
        height: 473px;
      }
    }
    .right-part-dashboard-explain{
      width: calc(60% - 20px);
      margin-top: 189px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 50px;
      .title-right-part{
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 50px;
        color: #220A6F;
        margin-bottom: 45px;
      }
      .paragraph{
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        text-align: left;
        margin-bottom: 62px;
      }
      .dashboard-skill{
        display: flex;
        flex-wrap: wrap;
        .description{
          width:calc(50% - 20px);
          margin-right: 20px;
          margin-bottom: 10px;
          &:nth-child(2n + 2){
            margin-right: 20px;
          }
        }
      }
    }
  }
  .scroll{
    margin-top: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 111;
    justify-content: flex-end;
    .title-scroll{
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #000000;
      span{
        color: white;
      }
    }
    img{
      width: 15px;
      height: 25px;
    }
  }
}